import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Layout from '../../components/layout'
import Header from '../../components/header'
import Container from '../../components/container'
import Row from '../../components/row'
import Column from '../../components/column'
import StoryIntro from '../../components/story-intro'

SwiperCore.use([Navigation, Pagination, Autoplay])

const PageHeader = <Header title="EV Charger" />

const EvChargerPage = ({ location }) => {
    const images = useStaticQuery(graphql`
      {
        image: allFile(filter: {relativeDirectory: {eq: "stories/wallbox"}, name: {eq: "DSC07715"}}) {
          nodes {
            childImageSharp {
              gatsbyImageData(
                width: 510
                transformOptions: {
                  cropFocus: CENTER
                }
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
            name
          }
        }
        slider: allFile(filter: {relativeDirectory: {eq: "stories/wallbox"}, name: {ne: "DSC07715"}}, sort: {fields: name}) {
            nodes {
              childImageSharp {
                gatsbyImageData(
                  width: 510
                  height: 380
                  transformOptions: {
                    cropFocus: CENTER
                  }
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
              name
            }
        }
      }
    `)

    return (
        <Layout translatedPageLinks={[{ code: 'de', link: '/de/story/wallbox/' }, { code: 'en', link: '/en/story/ev-charger/' }]} pageLang="en" pageTitle="EV Charger" pageHeader={PageHeader} location={location}>
            <Container>
                <section>
                    <StoryIntro>
                        <p>
                            Electromobility is booming. 
                            The expansion of private and public charging infrastructure can hardly keep up at the moment. 
                            This results in new challenges for GE-T. 
                            Not only in the development of innovative EV Chargers, but also in the establishment of sustainable, optimally functioning systems of hardware and software for the necessary charging, load management and billing management. {` `}
                            {/* eslint-disable-next-line */}
                            At the center of this is the C11E EV Charger developed by GE-T for <a href="https://www.cubos.com/en/" target="_blank">CUBOS GmbH</a> in Premium, Smart and Go variants.
                        </p>
                    </StoryIntro>
                    <Row>
                        <Column columnWidth={2}>
                            <p>
                                As a specialist in charging infrastructure, CUBOS is the youngest company in the Wolfsburg-based ES-Tec Group. 
                                In 2020 alone, CUBOS has already installed more than 1,000 charging points since the market launch of the Elli ID.Charger – a Volkswagen Group brand. 
                                So far, installations have been mostly in private homes in the Wolfsburg city area and metropolitan region. 
                            </p>
                            <p>
                                Thanks to GE-T, CUBOS will be able to serve both B2C and B2B customers of all sizes with the C11E EV Chargers. 
                                The upcoming milestone of the project is the electrification of a parking garage in the Wolfsburg area with about 100 charging points. 
                                The focus is on AC charging in the 11 to 22 kW range during extended stays - such as working hours.
                            </p>
                        </Column>
                        <Column columnWidth={2}>
                            {<GatsbyImage image={getImage(images.image.nodes[0])} alt="" />}
                        </Column>
                    </Row>
                    <br />
                    <Row>
                        <Column columnWidth={2}>
                            <Swiper
                                navigation
                                loop
                                autoplay
                                pagination={{ clickable: true }}
                            >
                                {/* eslint-disable-next-line */}
                                {images.slider.nodes.map((image) => <SwiperSlide key={image.name}><GatsbyImage image={getImage(image)} alt="" /></SwiperSlide>)}
                            </Swiper>
                        </Column>
                        <Column columnWidth={2}>
                            <p>
                                In particular, the exact billing of the amount of electricity charged in each case is a challenge for every owners' association and every company - absolutely independent of its size. 
                                With the help of GE-T's innovative preliminary work, CUBOS will be able to take over the operation of all of a customer's charging points. 
                                To share one highlight: NFC or RFID technology ensures individual billing of the amount of electricity charged both at work and in the private household in one process, if both charging points developed by GE-T are operated by CUBOS.
                            </p>
                        </Column>
                    </Row>
                </section>
            </Container>
        </Layout>
    )
}

export default EvChargerPage